import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _070bc279 = () => interopDefault(import('..\\pages\\about.vue' /* webpackChunkName: "pages/about" */))
const _8e19c48c = () => interopDefault(import('..\\pages\\activateAd.vue' /* webpackChunkName: "pages/activateAd" */))
const _75a1dc8c = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages/contact" */))
const _f5d1b800 = () => interopDefault(import('..\\pages\\dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _e0aa9efc = () => interopDefault(import('..\\pages\\dashboard\\index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _04ca2bb4 = () => interopDefault(import('..\\pages\\dashboard\\applied-jobs.vue' /* webpackChunkName: "pages/dashboard/applied-jobs" */))
const _2d98f24a = () => interopDefault(import('..\\pages\\dashboard\\credits.vue' /* webpackChunkName: "pages/dashboard/credits" */))
const _f227b92c = () => interopDefault(import('..\\pages\\dashboard\\favourites.vue' /* webpackChunkName: "pages/dashboard/favourites" */))
const _6613ef48 = () => interopDefault(import('..\\pages\\dashboard\\invoices\\index.vue' /* webpackChunkName: "pages/dashboard/invoices/index" */))
const _1a6823f5 = () => interopDefault(import('..\\pages\\dashboard\\job\\index.vue' /* webpackChunkName: "pages/dashboard/job/index" */))
const _720e63ba = () => interopDefault(import('..\\pages\\dashboard\\manage-ads\\index.vue' /* webpackChunkName: "pages/dashboard/manage-ads/index" */))
const _2cf23b1b = () => interopDefault(import('..\\pages\\dashboard\\manage-candidate.vue' /* webpackChunkName: "pages/dashboard/manage-candidate" */))
const _5a691d18 = () => interopDefault(import('..\\pages\\dashboard\\me.vue' /* webpackChunkName: "pages/dashboard/me" */))
const _28cf431c = () => interopDefault(import('..\\pages\\dashboard\\job\\_jobId\\index.vue' /* webpackChunkName: "pages/dashboard/job/_jobId/index" */))
const _ac603afc = () => interopDefault(import('..\\pages\\faq.vue' /* webpackChunkName: "pages/faq" */))
const _1f297f56 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _6a0dd2ce = () => interopDefault(import('..\\pages\\logout.vue' /* webpackChunkName: "pages/logout" */))
const _521c5dbe = () => interopDefault(import('..\\pages\\payment-unsuccessfull.vue' /* webpackChunkName: "pages/payment-unsuccessfull" */))
const _75e27f01 = () => interopDefault(import('..\\pages\\PaymentSuccess.vue' /* webpackChunkName: "pages/PaymentSuccess" */))
const _15b903fd = () => interopDefault(import('..\\pages\\PaymentSuccessRedirect.vue' /* webpackChunkName: "pages/PaymentSuccessRedirect" */))
const _5fddd59c = () => interopDefault(import('..\\pages\\pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _66643018 = () => interopDefault(import('..\\pages\\privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _2876716a = () => interopDefault(import('..\\pages\\resume-pricing.vue' /* webpackChunkName: "pages/resume-pricing" */))
const _5e7d491a = () => interopDefault(import('..\\pages\\terms.vue' /* webpackChunkName: "pages/terms" */))
const _18791dc0 = () => interopDefault(import('..\\pages\\admin\\socialMedia\\sendMessages.vue' /* webpackChunkName: "pages/admin/socialMedia/sendMessages" */))
const _4ca66914 = () => interopDefault(import('..\\pages\\jobs\\_mainCategory\\index.vue' /* webpackChunkName: "pages/jobs/_mainCategory/index" */))
const _cfba383e = () => interopDefault(import('..\\pages\\jobs\\_mainCategory\\_category\\index.vue' /* webpackChunkName: "pages/jobs/_mainCategory/_category/index" */))
const _458f3416 = () => interopDefault(import('..\\pages\\jobs\\_mainCategory\\_category\\item\\_id.vue' /* webpackChunkName: "pages/jobs/_mainCategory/_category/item/_id" */))
const _092a6a89 = () => interopDefault(import('..\\pages\\jobs\\_mainCategory\\_category\\_id.vue' /* webpackChunkName: "pages/jobs/_mainCategory/_category/_id" */))
const _26c39d3e = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _070bc279,
    name: "about"
  }, {
    path: "/activateAd",
    component: _8e19c48c,
    name: "activateAd"
  }, {
    path: "/contact",
    component: _75a1dc8c,
    name: "contact"
  }, {
    path: "/dashboard",
    component: _f5d1b800,
    children: [{
      path: "",
      component: _e0aa9efc,
      name: "dashboard"
    }, {
      path: "applied-jobs",
      component: _04ca2bb4,
      name: "dashboard-applied-jobs"
    }, {
      path: "credits",
      component: _2d98f24a,
      name: "dashboard-credits"
    }, {
      path: "favourites",
      component: _f227b92c,
      name: "dashboard-favourites"
    }, {
      path: "invoices",
      component: _6613ef48,
      name: "dashboard-invoices"
    }, {
      path: "job",
      component: _1a6823f5,
      name: "dashboard-job"
    }, {
      path: "manage-ads",
      component: _720e63ba,
      name: "dashboard-manage-ads"
    }, {
      path: "manage-candidate",
      component: _2cf23b1b,
      name: "dashboard-manage-candidate"
    }, {
      path: "me",
      component: _5a691d18,
      name: "dashboard-me"
    }, {
      path: "job/:jobId",
      component: _28cf431c,
      name: "dashboard-job-jobId"
    }]
  }, {
    path: "/faq",
    component: _ac603afc,
    name: "faq"
  }, {
    path: "/login",
    component: _1f297f56,
    name: "login"
  }, {
    path: "/logout",
    component: _6a0dd2ce,
    name: "logout"
  }, {
    path: "/payment-unsuccessfull",
    component: _521c5dbe,
    name: "payment-unsuccessfull"
  }, {
    path: "/PaymentSuccess",
    component: _75e27f01,
    name: "PaymentSuccess"
  }, {
    path: "/PaymentSuccessRedirect",
    component: _15b903fd,
    name: "PaymentSuccessRedirect"
  }, {
    path: "/pricing",
    component: _5fddd59c,
    name: "pricing"
  }, {
    path: "/privacy",
    component: _66643018,
    name: "privacy"
  }, {
    path: "/resume-pricing",
    component: _2876716a,
    name: "resume-pricing"
  }, {
    path: "/terms",
    component: _5e7d491a,
    name: "terms"
  }, {
    path: "/admin/socialMedia/sendMessages",
    component: _18791dc0,
    name: "admin-socialMedia-sendMessages"
  }, {
    path: "/:mainCategory",
    component: _4ca66914,
    name: "jobs-mainCategory"
  }, {
    path: "/:mainCategory?/:category",
    component: _cfba383e,
    name: "jobs-mainCategory-category"
  }, {
    path: "/:mainCategory?/:category/item/:id?",
    component: _458f3416,
    name: "jobs-mainCategory-category-item-id"
  }, {
    path: "/:mainCategory?/:category/:id",
    component: _092a6a89,
    name: "jobs-mainCategory-category-id"
  }, {
    path: "/",
    component: _26c39d3e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
